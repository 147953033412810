@import "./styles/fonts.scss";

#root{
  width: 100%;
  display: flex;
  justify-content: center;
}

body {
  // width: 1024px;
  display: flex;
  justify-content: center;
  margin: 0;
  font-family: Repro-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  margin: 0;
  padding: 0;
}