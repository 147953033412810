@import "../../styles/colors.scss";

.footer{
    background-color: $black;
    color: $white;
    display: flex;
    justify-content: center;

    &__container{
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(12, 72px);
        grid-template-rows: repeat(4, 73px);
        padding: 0px 14px;
        width: 100%;
        max-width: 1024px;
        box-sizing: border-box;
        align-self: center;
        margin-bottom: 24px;
    }

    &__lead{
        font-size: 32px;
        line-height: 36px;
        color: $white;
    }
    &__contacts-button{
        width: 196px;
        height: 24px;
        background-color: transparent;
        border: solid 2px white;
        color: white;
        font-size: 20px;
        line-height: 24px;
        text-decoration: none;
        padding: 12px;
        display: inline-block;
        text-align: center;

        &:hover{
            cursor: pointer;
            background-color: $link-color;
            border-color: $link-color;
        }
    }
}