@import "../../styles/colors.scss";

.cross-selling{
    display: flex;
    justify-content: center;
    color: $black-light;
    background-color: $white;

    &__container{
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(12, 72px);
        grid-template-rows: repeat(7, 73px);
        margin-bottom: 36px;
        padding: 0px 14px;
        width: 100%;
        max-width: 1024px;
        box-sizing: border-box;
        align-self: center;
    }
    &__header{
        font-family: Repro-Dot-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
        font-size: 32px;
        line-height: 36px;
        place-self: center;
        
    }
    &__image{
        justify-self: center;
        margin-top: 24px;
    }
    &__content{
        font-size: 18px;
        line-height: 24px;
        margin-top: 18px;
    }
    &__content-link{
        color: $black-light;
        text-decoration: none;
        border-bottom: solid 1px;
        &:hover{
            color: $link-color;
            cursor: pointer;
        }
    }
    &__content-dates{
        color: $black-light;
        font-family: Repro-Dot-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
        font-size: 32px;
        line-height: 36px;
        display: inline-block;
        margin-top: 60px;
    }
    
}